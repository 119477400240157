<template>
  <Menu
    bottom-left
    label="apps"
    class="mx-10 w-8 Icons"
  >
    <template v-slot:title>
      <icon
        size="s"
        class-name="h-full mx-auto self-center "
        icon-name="hambuger"
      />
    </template>
    <template v-slot:dropdownTitle>
      <div
        class=" pb-2 text-lg font-bold"
      >
        Go to App
      </div>
    </template>
    <div
      style="width:315px"
      class="py-5 px-3 grid grid-cols-12 gap-4"
    >
      <button
        v-for="(app, index) in apps"
        class="child col-span-4 text-center text-flame"
        :key="index"
      >
        <!-- :class="currentName === 'humanar' ? 'grayscale' : ''" -->
        <icon
          :icon-name="app.iconName"
          :class="app.name === 'HUMANAR' ? 'text-flame' : 'optima'"
          class="ml-6"
          size="l"
        />
        <h1 class="">
          {{ app.name }}
        </h1>
      </button>
    </div>
  </Menu>
</template>

<script>
import Menu from '@/components/Menu';
import Icon from '@/components/Icon';

export default {
  components: {
    Icon,
    Menu,
  },
  data() {
    return {
      currentName: 'humanar',
      apps: [
        {
          name: 'HUMANAR',
          iconName: 'humanar_logo',
        },
        {
          name: 'OPTIMA',
          iconName: 'optima_logo',
        },
      ],
    };
  },

};
</script>

<style>
.child{
    color:#321C3B
}
.grayscaled {
  filter: grayscale(100%);
}
.optima{
    color: #1034A6;
}
</style>
